<template>
    <div class=" hidden bg-grey-600 mx-auto">

        <footer class="p-4 lg:px-52  md:px-6 md:py-8 ">
            <div class="sm:flex sm:items-center sm:justify-between">
                <a href="#" target="_blank" class="flex items-center mb-4 sm:mb-0">
                    <img src="/images/chiconi.png" class="mr-4 h-12" alt="Flowbite Logo" />
                    <span class="self-center text-4xl ml-6 font-semibold whitespace-nowrap dark:text-red-600">MAY SOCHIC</span>
                </a>
                <ul class="flex flex-wrap items-center mb-6 sm:mb-0">
                   
                    <li>
                        <NuxtLink to="/policy" class="mr-4 text-sm text-gray-500 hover:text-red-500 duration-500  hover:font-bold md:mr-6 dark:text-gray-700">Privacy
                            Policy</NuxtLink>
                    </li>
                    
                    <li>
                        <NuxtLink to="/contact" class="text-sm text-gray-500 hover:text-red-500  duration-500 hover:font-bold dark:text-gray-700">Contact</NuxtLink>
                    </li>
                </ul>
            </div>
            <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
            <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-600">                 © Droits d'auteur 2023. Tous droits réservés.

            </span>
        </footer>


    </div>

    <div>

        <!-- Footer -->
        <div class="footer">
            <div class="container px-4 sm:px-8">
                <h4 class="mb-8 lg:max-w-3xl lg:mx-auto">MAY SOCHIC,  L'application officielle de la Commune de Chiconi. <a class="text-indigo-600 hover:text-gray-500"
                        href="mailto:email@domain.com">info@maysochic.fr</a></h4>
                <div class="social-container">
                    <span class="fa-stack">
                        <a href="#your-link">
                            <i class="fas fa-circle fa-stack-2x"></i>
                            <i class="fab fa-facebook-f fa-stack-1x"></i>
                        </a>
                    </span>
                    
            </div> <!-- end of social-container -->
        </div> <!-- end of container -->
    </div> <!-- end of footer -->
    <!-- end of footer -->


    <!-- Copyright -->
    <div class="copyright">
        <div class="container px-4 sm:px-8 lg:grid lg:grid-cols-3">
            <ul class="mb-4 list-unstyled p-small">
                 <li class="mb-2"><NuxtLink to="/policy">Politique de confidentialité</NuxtLink></li>
            </ul>
            <p class="pb-2 p-small statement">  © Droits d'auteur 2023. Tous droits réservés.</p>

            <p class="pb-2 p-small statement">Distribué par:<a href="#"
                    class="no-underline">SAS METIC</a></p>
        </div>

        <!-- end of container -->
    </div> <!-- end of copyright -->
    <!-- end of copyright -->

    </div>
     
</template>
 
