<script setup lang="ts">
import { ref } from "vue";
import { ClearAllIcon, Menu2Icon, ChevronDownIcon, BrandAppleIcon, BrandGooglePlayIcon, LoginIcon } from 'vue-tabler-icons';

const open = ref(false)
const dropdownNavbar = ref(false)

function dropdownToggler() {
    dropdownNavbar.value = !dropdownNavbar.value
}

let selected = ref(1);
if (process.client) {
    let activeTab = localStorage.getItem("maysochi-active-tab");
    selected = activeTab;
    console.log(activeTab)
}
function chooseMenu(tab: any) {
    selected = tab
    localStorage.setItem('maysochi-active-tab', tab)
}

</script>
 
  
<template>
    <!-- Navigation -->
    <nav class="navbar fixed-top">
        <div class="container sm:px-4 lg:px-8 flex flex-wrap items-center justify-between lg:flex-nowrap">

            <!-- Text Logo - Use this if you don't have a graphic logo -->
            <!-- <a class="text-gray-800 font-semibold text-3xl leading-4 no-underline page-scroll" href="index.html">Pavo</a> -->

            <!-- Image Logo -->
            <nuxt-link @click="chooseMenu(1)" to="/"
                class="inline-block mr-4 py-0.5 text-xl whitespace-nowrap hover:no-underline focus:no-underline">
                <img src="/images/chiconi.png" alt="alternative" class="h-10" />
            </nuxt-link>

            <button
                class="background-transparent rounded text-xl leading-none hover:no-underline focus:no-underline lg:hidden lg:text-gray-400"
                type="button" data-toggle="offcanvas">
                <span class="navbar-toggler-icon inline-block w-8 h-8 align-middle"></span>
            </button>

            <div class="navbar-collapse offcanvas-collapse lg:flex lg:flex-grow lg:items-center" id="navbarsExampleDefault">
                <ul class="pl-0 mt-3 mb-2 ml-auto flex flex-col list-none lg:mt-0 lg:mb-0 lg:flex-row">

                    <li>
                        <nuxt-link @click="chooseMenu(1)" to="/" class="nav-link page-scroll active">Accueil <span
                                class="sr-only">(current)</span></nuxt-link>
                    </li>
                    <li>
                        <nuxt-link @click="chooseMenu(2)" to="/contact" class="nav-link page-scroll"> Contact</nuxt-link>
                    </li>

                </ul>
                <span class="block ml-16 flex gap-2 lg:ml-3.5">
                    <nuxt-link class="group" to="/auth/login">
                        <div
                            class="no-underline group flex bg-red-500 rounded-full text-white items-center py-2 duration-200  px-5  
                            group-hover:border border-red-500 group-hover:text-red-400 ">
                            <LoginIcon class="text-white group-hover:text-red-500" /> Se connecter
                        </div>
                    </nuxt-link>
                  

                </span>
            </div> <!-- end of navbar-collapse -->
        </div> <!-- end of container -->
    </nav> <!-- end of navbar -->
    <!-- end of navigation -->
</template>

